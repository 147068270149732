import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class FtPaginatorI18n {
	constructor(private _translate: TranslateService) {}

	public getPaginatorI18n(): MatPaginatorIntl {
		const paginatorConfig = new MatPaginatorIntl();

		paginatorConfig.itemsPerPageLabel =
			this._translate.instant('items_per_page');
		paginatorConfig.nextPageLabel = this._translate.instant('next_page');
		paginatorConfig.previousPageLabel =
			this._translate.instant('previous_page');
		paginatorConfig.firstPageLabel = this._translate.instant('first_page');
		paginatorConfig.lastPageLabel = this._translate.instant('last_page');
		paginatorConfig.getRangeLabel = this._getRangeLabel.bind(this);

		return paginatorConfig;
	}

	private _getRangeLabel(
		page: number,
		pageSize: number,
		length: number
	): string {
		if (length === 0 || pageSize === 0) {
			return this._translate.instant('range_page_1', { length });
		}
		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		// If the start index exceeds the list length, do not try and fix the end index to the end.
		const endIndex =
			startIndex < length
				? Math.min(startIndex + pageSize, length)
				: startIndex + pageSize;

		return this._translate.instant('range_page_2', {
			startIndex: startIndex + 1,
			endIndex,
			length,
		});
	}
}
