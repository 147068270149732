import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewChild, NgModule } from '@angular/core';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i3 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import * as i2 from '@angular/material/checkbox';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import * as i1 from '@angular/cdk/a11y';
function FormlyFieldCheckbox_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 2);
    i0.ɵɵtext(1, "*");
    i0.ɵɵelementEnd();
  }
}
class FormlyFieldCheckbox extends FieldType {
  constructor(renderer, focusMonitor) {
    super();
    this.renderer = renderer;
    this.focusMonitor = focusMonitor;
    this.defaultOptions = {
      props: {
        hideFieldUnderline: true,
        indeterminate: true,
        floatLabel: 'always',
        hideLabel: true,
        color: 'accent' // workaround for https://github.com/angular/components/issues/18465
      }
    };
  }
  onContainerClick(event) {
    this.checkbox.focus();
    super.onContainerClick(event);
  }
  ngAfterViewInit() {
    if (this.checkbox) {
      this.focusMonitor.monitor(this.checkbox._inputElement, true).subscribe(focusOrigin => {
        this.field.focus = !!focusOrigin;
        this.stateChanges.next();
        if (focusOrigin) {
          this.props.focus && this.props.focus(this.field);
        } else {
          this.props.blur && this.props.blur(this.field);
        }
      });
    }
  }
  ngAfterViewChecked() {
    if (this.required !== this._required && this.checkbox && this.checkbox._inputElement) {
      this._required = this.required;
      const inputElement = this.checkbox._inputElement.nativeElement;
      if (this.required) {
        this.renderer.setAttribute(inputElement, 'required', 'required');
      } else {
        this.renderer.removeAttribute(inputElement, 'required');
      }
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.checkbox) {
      this.focusMonitor.stopMonitoring(this.checkbox._inputElement);
    }
  }
}
FormlyFieldCheckbox.ɵfac = function FormlyFieldCheckbox_Factory(t) {
  return new (t || FormlyFieldCheckbox)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.FocusMonitor));
};
FormlyFieldCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldCheckbox,
  selectors: [["formly-field-mat-checkbox"]],
  viewQuery: function FormlyFieldCheckbox_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(MatCheckbox, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.checkbox = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 10,
  consts: [[3, "formControl", "id", "name", "formlyAttributes", "tabIndex", "indeterminate", "color", "labelPosition"], ["aria-hidden", "true", "class", "mat-form-field-required-marker mat-mdc-form-field-required-marker", 4, "ngIf"], ["aria-hidden", "true", 1, "mat-form-field-required-marker", "mat-mdc-form-field-required-marker"]],
  template: function FormlyFieldCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "mat-checkbox", 0);
      i0.ɵɵtext(1);
      i0.ɵɵtemplate(2, FormlyFieldCheckbox_span_2_Template, 2, 0, "span", 1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("formControl", ctx.formControl)("id", ctx.id)("name", ctx.field.name)("formlyAttributes", ctx.field)("tabIndex", ctx.props.tabindex)("indeterminate", ctx.props.indeterminate && ctx.formControl.value == null)("color", ctx.props.color)("labelPosition", ctx.props.labelPosition);
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate1(" ", ctx.props.label, " ");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.required && ctx.props.hideRequiredMarker !== true);
    }
  },
  dependencies: [i2.MatCheckbox, i3.NgControlStatus, i3.FormControlDirective, i4.ɵFormlyAttributes, i5.NgIf],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldCheckbox, [{
    type: Component,
    args: [{
      selector: 'formly-field-mat-checkbox',
      template: `
    <mat-checkbox
      [formControl]="formControl"
      [id]="id"
      [name]="field.name"
      [formlyAttributes]="field"
      [tabIndex]="props.tabindex"
      [indeterminate]="props.indeterminate && formControl.value == null"
      [color]="props.color"
      [labelPosition]="props.labelPosition"
    >
      {{ props.label }}
      <span
        *ngIf="props.required && props.hideRequiredMarker !== true"
        aria-hidden="true"
        class="mat-form-field-required-marker mat-mdc-form-field-required-marker"
        >*</span
      >
    </mat-checkbox>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i1.FocusMonitor
    }];
  }, {
    checkbox: [{
      type: ViewChild,
      args: [MatCheckbox, {
        static: true
      }]
    }]
  });
})();
class FormlyMatCheckboxModule {}
FormlyMatCheckboxModule.ɵfac = function FormlyMatCheckboxModule_Factory(t) {
  return new (t || FormlyMatCheckboxModule)();
};
FormlyMatCheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMatCheckboxModule
});
FormlyMatCheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, MatCheckboxModule, FormlyMatFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'checkbox',
      component: FormlyFieldCheckbox,
      wrappers: ['form-field']
    }, {
      name: 'boolean',
      extends: 'checkbox'
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMatCheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldCheckbox],
      imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule, FormlyMatFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'checkbox',
          component: FormlyFieldCheckbox,
          wrappers: ['form-field']
        }, {
          name: 'boolean',
          extends: 'checkbox'
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldCheckbox, FormlyMatCheckboxModule };
