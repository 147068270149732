import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticComponent } from './statistic.component';
import { StatisticRoutingModule } from './statistic-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FireSharedModule } from '../shared';
import { StatisticService } from './statistic.service';
import { ExamPerMonthComponent } from './exam-per-month/exam-per-month.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StateDirective } from './state.directive';
import { PhysicianExamStateComponent } from './physician-exam-state/physician-exam-state.component';
import { ReferringExamStateComponent } from './referring-exam-state/referring-exam-state.component';
import { TechnicianExamStateComponent } from './technician-exam-state/technician-exam-state.component';
import { AetExamStateComponent } from './aet-exam-state/aet-exam-state.component';
import { FtPivotTableComponent } from './ft-pivot-table/ft-pivot-table.component';
import { ReasonForExamStateComponent } from './reason-for-exam-state/reason-for-exam-state.component';
import { PatientStateComponent } from './patient-state/patient-state.component';
import { PaymentDateStateComponent } from './payment-date-state/payment-date-state.component';
import { FlexmonsterPivotModule } from 'ngx-flexmonster';
import { PrintFilesComponent } from './print-files/print-files.component';
import { FileSystemService } from './file-system.service';

@NgModule({
	imports: [
		CommonModule,
		FireSharedModule,
		StatisticRoutingModule,
		MatSidenavModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		FlexmonsterPivotModule,
		FormsModule,
	],
	declarations: [
		StatisticComponent,
		ExamPerMonthComponent,
		StateDirective,
		PhysicianExamStateComponent,
		ReferringExamStateComponent,
		TechnicianExamStateComponent,
		AetExamStateComponent,
		FtPivotTableComponent,
		ReasonForExamStateComponent,
		PatientStateComponent,
		PaymentDateStateComponent,
		PrintFilesComponent,
	],
	providers: [StatisticService, FileSystemService],
})
export class StatisticModule {}
