import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientComponent } from './patient.component';
import { PatientRoutingModule } from './patient-routing.module';
import { PatientService } from './patient.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PatientListComponent } from './patient-list/patient-list.component';
import { FireSharedModule, SharedService } from '../shared';
import { SettingService } from '../setting/setting.service';
import { PatientFolderComponent } from './patient-folder/patient-folder.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		FireSharedModule,
		MatCardModule,
		MatToolbarModule,
		ReactiveFormsModule,
		MatNativeDateModule,
		MatRadioModule,
		MatSelectModule,
		MatOptionModule,
		MatTabsModule,
		MatChipsModule,
		MatListModule,
		MatDatepickerModule,
		PatientRoutingModule,
		MatGridListModule,
	],
	declarations: [
		PatientComponent,
		PatientListComponent,
		PatientFolderComponent,
	],
	providers: [PatientService, SharedService, SettingService],
})
export class PatientModule {}
