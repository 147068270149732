import {
	Component,
	ElementRef,
	Inject,
	isDevMode,
	OnInit,
	ViewChild,
	OnDestroy,
	signal,
} from '@angular/core';
import { Profile, WsMessage } from './model';
import { Title } from '@angular/platform-browser';
import { AuthService } from './auth';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { getAppType, LocalStorageService, UserUpdateComponent } from './shared';
import { AppInfoComponent } from './app-info/app-info.component';
import $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { WsService } from './ws.service';
import { Message, MessagePopupComponent } from './messaging';
import { NavigationEnd, Router } from '@angular/router';
import { bufferTime, filter, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchedulerService } from './scheduler/scheduler.service';
import { SettingService } from './setting/setting.service';
import { BarcodeSearchComponent } from './shared/barcode-search/barcode-search.component';
import { AppConfigService } from './app-config.service';
import { logProductName } from './utils';
import { Subscription } from 'rxjs';

@Component({
	selector: 'ft-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
	username: string;
	profile: Profile;
	user: any;
	fullScreen = false;
	readonly logo: string;
	public mobile: boolean;
	public totalNotifs: number = null;
	@ViewChild('sound_player', { static: false }) soundPlayer: ElementRef;
	private curl: any;
	public readonly isDev: boolean;
	public readonly uiLanguage: string;

	private subs$: Subscription[] = [];

	public get userName(): string {
		return this.user
			? this.user.lastName + ' ' + this.user.firstName
			: 'Anonymous';
	}

	constructor(
		@Inject(DOCUMENT) public _document: any,
		private titleService: Title,
		private translate: TranslateService,
		private _authService: AuthService,
		private dialog: MatDialog,
		private _platform: Platform,
		private _ws: WsService,
		private _router: Router,
		private _localStorage: LocalStorageService,
		private _scheduler: SchedulerService,
		private _setting: SettingService,
		private _config: AppConfigService,
		private _snack: MatSnackBar
	) {
		this.logo = this._config.logo;
		const appType = getAppType(this.logo);
		logProductName(appType);

		this.setTitle(appType === 'cvis' ? 'FireCVIS' : 'FireRIS');

		this.isDev = isDevMode();

		this.subs$.push(
			this._scheduler.scanIdCard.subscribe(_ => {
				const eid_url = localStorage.getItem('eid_api_url');
				const url =
					JSON.parse(eid_url) + `/eid/scan_eid/${this.user.id}`;
				console.log(
					`%c${url}`,
					'color:#8888ff;font-weight:bold;border:2px solid grey;padding:8px;border-radius:6px;'
				);

				window.open(url);
			})
		);

		this.mobile = this._platform.IOS || this._platform.ANDROID;

		this.user = this._localStorage.getItem('user');
		this.profile = this.user.profile;
		this.uiLanguage = this._config.appLang;

		const chat_topic = 'chat/' + this.user.id;

		this.subs$.push(
			this._ws.observeTopic(chat_topic).subscribe(data => {
				if (data) {
					if (data.topic !== chat_topic) return;

					const message = JSON.parse(data.response) as WsMessage;
					if ('newMessage' === message.text) {
						const msg = message.data as Message;
						if (
							msg.sender.id !== this.user.id &&
							!this.curl.startsWith('/messaging')
						) {
							this.totalNotifs += 1;
							this.playSound();
							this.showMessagePopup(msg);
						}
					}

					this._ws.chatUpdate.next(message);
				}
			})
		);

		this.subs$.push(
			this._ws
				.observeTopic('logout')
				.pipe(
					bufferTime(5000),
					map(arr => arr[arr.length - 1])
				)
				.subscribe(message => {
					if (!message || message.topic !== 'logout') return;

					const msg = JSON.parse(message.response) as WsMessage;
					const token = localStorage.getItem('access_token');
					const user = this.user?.username;

					if (msg.text !== token) {
						if (msg.data) {
							if (msg.data === user) this.logout();
							else return;
						}

						this.logout();
					}
				})
		);

		const generalSetting = this._config.generalSetting;
		if (
			generalSetting.idScannerConfigured &&
			generalSetting.scannerIdUrl?.startsWith('beid')
		) {
			const eid_card_topic = 'eid-reader/' + this.user.id;
			this._ws.observeExternalTopic(eid_card_topic).subscribe(data => {
				if (data) {
					const message = JSON.parse(data.response) as WsMessage;
					const msg = message.data;
					this._scheduler.beidCardData.next(msg);
				}
			});
		}

		this.subs$.push(
			this._router.events
				.pipe(filter(e1 => e1 instanceof NavigationEnd))
				.subscribe(e2 => (this.curl = e2['url']))
		);

		this.subs$.push(
			this._ws.soundPlayer.asObservable().subscribe(value => {
				if (value) this.playSound();
			})
		);
	}

	public changeUILanguage(lang: string) {
		this.subs$.push(
			this._setting
				.changeUILanguage(lang)
				.subscribe(ok => location.reload())
		);
	}

	private playSound() {
		if (this.soundPlayer) this.soundPlayer.nativeElement.play();
	}

	showAppInfo = () => this.dialog.open(AppInfoComponent, { width: '400px' });
	handleClick = signal<any | null>(null);

	public get darkTheme(): boolean {
		return this._localStorage.getItem('theme') === 'dark';
	}

	public toggleTheme(): void {
		const bodyClassList = document.getElementById('ft-body').classList;
		bodyClassList.toggle('dark-theme');
		if (bodyClassList.contains('dark-theme'))
			this._localStorage.setItem('theme', 'dark');
		else this._localStorage.setItem('theme', 'light');
	}

	ngOnInit(): void {
		let focusedElement;
		$(document).on(
			'dragover dragleave drop',
			'input.mat-input-element',
			false
		);

		$(document).on('focus', 'input.mat-input-element', function () {
			if (focusedElement === this) return;
			focusedElement = this;

			setTimeout(function () {
				focusedElement.select();
			}, 50);
		});
	}

	private showMessagePopup(message: Message): void {
		this._snack
			.openFromComponent(MessagePopupComponent, {
				horizontalPosition: 'end',
				verticalPosition: 'bottom',
				data: message,
				duration: 10000,
			})
			.afterDismissed()
			.subscribe(value => {
				if (value.dismissedByAction) {
					this.totalNotifs = null;
					this._router.navigate(['/messaging'], {
						queryParams: { roomId: message.roomId },
					});
				}
			});
	}

	public toggleFullScreen(): void {
		const element = this._document.documentElement;

		this.fullScreen =
			this._document.webkitIsFullScreen ||
			this._document.mozFullScreen ||
			false;

		element.requestFullScreen =
			element.requestFullScreen ||
			element.webkitRequestFullScreen ||
			element.mozRequestFullScreen ||
			function () {
				return false;
			};
		this._document.cancelFullScreen =
			this._document.cancelFullScreen ||
			this._document.webkitCancelFullScreen ||
			this._document.mozCancelFullScreen ||
			function () {
				return false;
			};

		this.fullScreen
			? this._document.cancelFullScreen()
			: element.requestFullScreen();
		this.fullScreen = screen.availHeight - 30 >= window.innerHeight;
	}

	public setTitle(newTitle: string): void {
		this.titleService.setTitle(this.translate.instant(newTitle));
	}

	public logout(): void {
		this._authService.logout().subscribe(_ => {
			this._router.navigate(['/login']).then();
		});
	}

	updateUser() {
		this.dialog
			.open(UserUpdateComponent, { data: this.user })
			.afterClosed()
			.subscribe(res => {
				if (res) this.logout();
			});
	}

	searchBarcode() {
		this.dialog.open(BarcodeSearchComponent, {
			minWidth: '100vw',
			minHeight: '100vh',
			panelClass: 'barcode-panel',
		});
	}

	goHome() {
		this._router.navigateByUrl(this.profile.defaultRoute).then();
	}

	logoutOthers() {
		this._authService
			.logoutOthers(localStorage.getItem('access_token'), true)
			.subscribe();
	}

	logoutMySessions() {
		this._authService
			.logoutOthers(localStorage.getItem('access_token'))
			.subscribe();
	}

	ngOnDestroy(): void {
		this.subs$.forEach(sub => sub?.unsubscribe());
	}
}
