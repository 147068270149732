<div class="popup-title">{{ 'NEW_CHAT' | translate }}</div>

@if (message) {
	<div
		class="popup-container"
		class="fx-layout-row-nowrap fx-content-space-between fx-gap-4">
		<div
			class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
			<div class="contact-initials" style="width: 42px">
				{{ message.sender.name | initials }}
			</div>

			<div
				class="fx-grow-1"
				class="fx-layout-column fx-content-center fx-items-start fx-gap-4">
				<div class="contact">{{ message.sender?.name }}</div>

				@switch (message.messageType) {
					@case ('TEXT') {
						<div class="message">{{ message.text }}</div>
					}
					@default {
						<div class="message">
							<mat-icon
								fontIcon="mdi-image"
								fontSet="mdi"></mat-icon>
							{{ message.messageType | translate }}
						</div>
					}
				}
			</div>
		</div>

		<div
			style="width: 72px"
			class="fx-layout-column fx-content-center fx-items-start"
			style="border-left: 1px solid #4e4e4e">
			<button (click)="open()" mat-button>
				{{ 'SHOW' | translate }}
			</button>
			<button (click)="dismiss()" color="warn" mat-button>
				{{ 'CLOSE' | translate }}
			</button>
		</div>
	</div>
}
