import { Component, Inject, OnInit } from '@angular/core';
import { Message } from '../models';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
	selector: 'ft-message-popup',
	templateUrl: './message-popup.component.html',
	styleUrls: ['./message-popup.component.scss'],
})
export class MessagePopupComponent implements OnInit {
	public message: Message;

	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
		private _snackRef: MatSnackBarRef<MessagePopupComponent>
	) {}

	ngOnInit(): void {
		this.message = this.data;
	}

	dismiss() {
		this._snackRef.dismiss();
	}

	open() {
		this._snackRef.dismissWithAction();
	}
}
