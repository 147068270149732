import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
export { FieldType } from '@ngx-formly/material/form-field';
import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { FormlyMatInputModule } from '@ngx-formly/material/input';
import { FormlyMatTextAreaModule } from '@ngx-formly/material/textarea';
import { FormlyMatRadioModule } from '@ngx-formly/material/radio';
import { FormlyMatCheckboxModule } from '@ngx-formly/material/checkbox';
import { FormlyMatMultiCheckboxModule } from '@ngx-formly/material/multicheckbox';
import { FormlyMatSelectModule } from '@ngx-formly/material/select';
class FormlyMaterialModule {}
FormlyMaterialModule.ɵfac = function FormlyMaterialModule_Factory(t) {
  return new (t || FormlyMaterialModule)();
};
FormlyMaterialModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMaterialModule
});
FormlyMaterialModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[FormlyMatFormFieldModule, FormlyMatInputModule, FormlyMatTextAreaModule, FormlyMatRadioModule, FormlyMatCheckboxModule, FormlyMatMultiCheckboxModule, FormlyMatSelectModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMaterialModule, [{
    type: NgModule,
    args: [{
      imports: [FormlyMatFormFieldModule, FormlyMatInputModule, FormlyMatTextAreaModule, FormlyMatRadioModule, FormlyMatCheckboxModule, FormlyMatMultiCheckboxModule, FormlyMatSelectModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of material
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyMaterialModule };
