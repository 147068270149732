import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private _authService = inject(AuthService);

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		request = this._addImagingCenterHeader(request);

		/* TODO: consider use a lightweight method to check and reuse the authentications
         const token = this._authService.getAccessToken();

              if (token) request = this._addToken(request, token);
               request = this._addImagingCenterHeader(request);

               return next.handle(request).pipe(
                   catchError(error => {
                       if (error.status === 401) {
                           const refreshToken = this._authService.getRefreshToken();
                           console.log('refreshToken', refreshToken);
                           // Use refresh token to obtain new JWT token
                           this._authService.refreshToken(refreshToken).subscribe(tokens => {
                               // Update stored token and retry original request
                               console.log('accessToken', tokens.access_token);
                               request = this._addToken(request, tokens.access_token);
                           });
                       }
                       throw error;
                   })
               );*/

		const refreshToken = this._authService.getRefreshToken();

		if (this._authService.isLoggedIn()) {
			request = this._addToken(
				request,
				this._authService.getAccessToken()
			);
		} else if (refreshToken && this._authService.refreshTokenIsValid()) {
			request = this._addToken(request, refreshToken);
		} else request = this._addToken(request, '');

		return next.handle(request).pipe(
			catchError(error => {
				if (refreshToken && [401, 403].includes(error.status)) {
					return this._handleError(request, next);
				} else {
					if (this._authService.shouldLogout())
						this._authService.logout().subscribe(_ => null);
					return throwError(() => error);
				}
			})
		);
	}

	private _addToken(
		request: HttpRequest<any>,
		token: string
	): HttpRequest<any> {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	private _addImagingCenterHeader(
		request: HttpRequest<any>
	): HttpRequest<any> {
		const imagingCenterId = localStorage.getItem('ImagingCenter');
		return request.clone({
			setHeaders: {
				'Imaging-Center': imagingCenterId ?? '1',
			},
		});
	}

	private _handleError(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return this._authService
			.refreshToken(this._authService.getRefreshToken())
			.pipe(
				switchMap((tokens: any) => {
					return next.handle(
						this._addToken(request, tokens.access_token)
					);
				}),
				catchError(err => {
					return throwError(() => err);
				})
			);
	}
}
