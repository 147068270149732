import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingService } from '../setting.service';

import { assign } from 'lodash';
import { SharedService } from '../../shared';

@Component({
	selector: 'ft-static-resource-edit',
	templateUrl: './static-resource-edit.component.html',
	styleUrls: ['./static-resource-edit.component.scss'],
})
export class StaticResourceEditComponent implements OnInit {
	selectedItem: any;
	itemType: string;
	staticForm: FormGroup;
	aetForm: FormGroup;
	aets: any[];
	color = '#22F2F2';

	constructor(
		private fb: FormBuilder,
		public dialogRef: MatDialogRef<StaticResourceEditComponent>,
		private settingService: SettingService,
		private sharedService: SharedService
	) {
		this.staticForm = this.fb.group({
			id: '',
			value: '',
			description: '',
		});

		this.aetForm = this.fb.group({
			id: '',
			title: '',
		});
	}

	onSave(data: any) {
		switch (this.itemType) {
			case 'modality':
				assign(data, {
					defaultAETitle: this.aetForm.controls['title'].value,
					color: this.color,
				});

				this.saveModality(data);
				break;

			case 'spsStatus':
				assign(data, {
					color: this.color,
				});

				this.saveSPSStatus(data);
				break;
		}
	}

	saveSPSStatus(spsStatus: string) {
		this.settingService
			.saveSPSStatus(spsStatus)
			.subscribe(res => this.dialogRef.close(res));
	}

	saveModality(modality: any) {
		this.settingService
			.saveModality(modality)
			.subscribe(res => this.dialogRef.close(res));
	}

	getModalityAETitles() {
		this.sharedService
			.getModalityAETitles(this.selectedItem.id)
			.subscribe(aets => {
				this.aets = aets;

				const aet =
					aets.find(
						ae => ae.title === this.selectedItem.defaultAETitle
					) || {};

				this.aetForm.patchValue(aet);
			});
	}

	ngOnInit() {
		this.color = this.selectedItem.color || this.color;

		this.staticForm.patchValue(this.selectedItem);
		this.getModalityAETitles();
	}
}
