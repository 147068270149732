<div class="top-container">
	<mat-toolbar class="fx-height-46" tabindex="-1">
		<button
			mat-icon-button
			class="fx-show-lt-sm fx-hide-gt-xs"
			[matMenuTriggerFor]="navMenu">
			<mat-icon fontSet="mdi" fontIcon="mdi-menu"></mat-icon>
		</button>

		<button class="logo" mat-button tabindex="-1" (click)="goHome()">
			<img
				[alt]="logo"
				class="app-angular-logo"
				src="../assets/images/{{ logo }}" />
		</button>

		<mat-menu #navMenu="matMenu" [overlapTrigger]="true">
			@if (profile?.schedulerModule) {
				<button
					mat-menu-item
					(click)="setTitle('Admission')"
					routerLink="/scheduler"
					routerLinkActive="active">
					<mat-icon
						fontIcon="mdi-calendar-clock"
						fontSet="mdi"></mat-icon>
					{{ 'SCHEDULE.NAME' | translate }}
				</button>
			}

			@if (profile?.workflowModule) {
				<button
					mat-menu-item
					(click)="setTitle('Workflow')"
					routerLink="/workflow"
					routerLinkActive="active">
					<mat-icon
						fontIcon="mdi-clipboard-flow"
						fontSet="mdi"></mat-icon>
					{{ 'WORKFLOW.NAME' | translate }}
				</button>
			}

			@if (profile?.reportingModule) {
				<button
					mat-menu-item
					(click)="setTitle('Reporting')"
					routerLink="/reporting"
					routerLinkActive="active">
					<mat-icon fontIcon="mdi-book-open" fontSet="mdi"></mat-icon>
					{{ 'REPORTING.NAME' | translate }}
				</button>
			}

			@if (profile?.patientModule) {
				<button
					mat-menu-item
					(click)="setTitle('Patients')"
					routerLink="/patients"
					routerLinkActive="active">
					<mat-icon fontIcon="mdi-contacts" fontSet="mdi"></mat-icon>
					{{ 'PATIENTS' | translate }}
				</button>
			}

			@if (profile?.physicianModule) {
				<button
					mat-menu-item
					(click)="setTitle('Physicians')"
					routerLink="/physicians"
					routerLinkActive="active">
					<mat-icon
						fontIcon="mdi-account-group"
						fontSet="mdi"></mat-icon>
					{{ 'Physicians' | translate }}
				</button>
			}

			@if (profile?.statisticModule) {
				<button
					mat-menu-item
					(click)="setTitle('Statistic')"
					routerLink="/statistic"
					routerLinkActive="active">
					<mat-icon
						fontIcon="mdi-chart-line"
						fontSet="mdi"></mat-icon>
					{{ 'STATISTIC' | translate }}
				</button>
			}

			@if (profile?.loggingModule) {
				<button
					mat-menu-item
					(click)="setTitle('Logs')"
					routerLink="/logs"
					routerLinkActive="active">
					<mat-icon
						class="logs-icon"
						fontIcon="mdi-database-clock"
						fontSet="mdi"></mat-icon>
					{{ 'LOGS' | translate }}
				</button>
			}

			<button
				mat-menu-item
				(click)="setTitle('Messaging'); totalNotifs = null"
				routerLink="/messaging"
				routerLinkActive="active">
				<mat-icon
					class="chat-icon"
					[matBadge]="totalNotifs"
					aria-hidden="false"
					fontIcon="mdi-forum"
					fontSet="mdi"
					matBadgeColor="warn"
					matBadgeSize="small"></mat-icon>
				{{ 'MESSAGING' | translate }}
			</button>

			<mat-divider></mat-divider>

			@if (profile?.settingModule) {
				<button
					mat-menu-item
					(click)="setTitle('Settings')"
					routerLink="/setting"
					routerLinkActive="active">
					<mat-icon
						class="setting-icon"
						fontIcon="mdi-cog"
						fontSet="mdi"></mat-icon>
					{{ 'SETTINGS' | translate }}
				</button>
			}

			<button mat-menu-item (click)="showAppInfo()">
				<mat-icon
					class="about-icon"
					fontIcon="mdi-information"
					fontSet="mdi"></mat-icon>
				{{ 'APP_INFO' | translate }}
			</button>
		</mat-menu>

		<div
			class="fx-layout-row-nowrap fx-content-space-between fx-items-center fx-hide-lt-sm fx-show-gt-xs"
			[style.width.%]="100">
			@if (profile?.schedulerModule) {
				<a
					(click)="setTitle('Admission')"
					mat-button
					class="main-nav-button"
					routerLink="/scheduler"
					routerLinkActive="active"
					tabindex="-1">
					<mat-icon
						fontIcon="mdi-calendar-clock"
						fontSet="mdi"></mat-icon>
					<span class="menu-btn fx-hide-sm fx-hide-xs">{{
						'SCHEDULE.NAME' | translate
					}}</span>
				</a>
			}

			@if (profile?.workflowModule) {
				<a
					(click)="setTitle('Workflow')"
					mat-button
					class="main-nav-button"
					routerLink="/workflow"
					routerLinkActive="active"
					tabindex="-1">
					<mat-icon
						fontIcon="mdi-clipboard-flow"
						fontSet="mdi"></mat-icon>
					<span class="menu-btn fx-hide-sm fx-hide-xs">{{
						'WORKFLOW.NAME' | translate
					}}</span>
				</a>
			}

			@if (profile?.reportingModule) {
				<a
					(click)="setTitle('Reporting')"
					mat-button
					class="main-nav-button"
					routerLink="/reporting"
					routerLinkActive="active"
					tabindex="-1">
					<mat-icon fontIcon="mdi-book-open" fontSet="mdi"></mat-icon>
					<span class="menu-btn fx-hide-sm fx-hide-xs">{{
						'REPORTING.NAME' | translate
					}}</span>
				</a>
			}

			@if (profile?.patientModule) {
				<a
					(click)="setTitle('Patients')"
					mat-button
					class="main-nav-button"
					routerLink="/patients"
					routerLinkActive="active"
					tabindex="-1">
					<mat-icon fontIcon="mdi-contacts" fontSet="mdi"></mat-icon>
					<span class="menu-btn fx-hide-sm fx-hide-xs">{{
						'PATIENTS' | translate
					}}</span>
				</a>
			}

			@if (profile?.physicianModule) {
				<a
					(click)="setTitle('Physicians')"
					mat-button
					class="main-nav-button"
					routerLink="/physicians"
					routerLinkActive="active"
					tabindex="-1">
					<mat-icon
						fontIcon="mdi-account-group"
						fontSet="mdi"></mat-icon>
					<span class="menu-btn fx-hide-sm fx-hide-xs">{{
						'Physicians' | translate
					}}</span>
				</a>
			}

			@if (profile?.statisticModule) {
				<a
					(click)="setTitle('Statistic')"
					mat-button
					class="main-nav-button"
					routerLink="/statistic"
					routerLinkActive="active"
					tabindex="-1">
					<mat-icon
						fontIcon="mdi-chart-line"
						fontSet="mdi"></mat-icon>
					<span class="menu-btn fx-hide-sm fx-hide-xs">{{
						'STATISTIC' | translate
					}}</span>
				</a>
			}

			<span class="fx-grow-1"></span>

			<h6 class="fx-hide-lt-md">
				<b>{{ 'HELLO' | translate }}</b> {{ userName }}
			</h6>

			<a
				mat-icon-button
				(keyup.enter)="handleClick()"
				[matTooltip]="'BARCODE_SEARCH' | translate"
				tabindex="-1"
				(click)="searchBarcode()">
				<mat-icon
					class="barcode-icon"
					fontSet="mdi"
					fontIcon="mdi-barcode-scan"></mat-icon>
			</a>
			@if (profile?.loggingModule) {
				<a
					(click)="setTitle('Logs')"
					mat-icon-button
					matTooltip="{{ 'LOGS' | translate }}"
					routerLink="/logs"
					routerLinkActive="active"
					tabindex="-1">
					<mat-icon
						class="logs-icon"
						fontIcon="mdi-database-clock"
						fontSet="mdi"></mat-icon>
				</a>
			}

			<a
				(click)="showAppInfo()"
				mat-icon-button
				matTooltip="{{ 'APP_INFO' | translate }}"
				(keyup.enter)="handleClick()"
				tabindex="-1">
				<mat-icon
					class="about-icon"
					fontIcon="mdi-information"
					fontSet="mdi"
					style="color: #00bcd4"></mat-icon>
			</a>

			@if (profile) {
				<a
					(click)="setTitle('Messaging'); totalNotifs = null"
					mat-icon-button
					matTooltip="{{ 'MESSAGING' | translate }}"
					routerLink="/messaging"
					routerLinkActive="active"
					tabindex="-1">
					<mat-icon
						class="chat-icon"
						[matBadge]="totalNotifs"
						aria-hidden="false"
						fontIcon="mdi-forum"
						fontSet="mdi"
						matBadgeColor="warn"
						matBadgeSize="small"></mat-icon>
				</a>
			}

			<a
				(click)="toggleFullScreen()"
				mat-icon-button
				matTooltip="{{ 'TOGGLE_FULLSCREEN' | translate }}"
				(keyup.enter)="handleClick()"
				tabindex="-1">
				<mat-icon
					fontIcon="{{
						fullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
					}}"
					fontSet="mdi"></mat-icon>
			</a>
			@if (profile?.settingModule) {
				<a
					(click)="setTitle('Settings')"
					mat-icon-button
					matTooltip="{{ 'SETTINGS' | translate }}"
					routerLink="/setting"
					routerLinkActive="active"
					tabindex="-1">
					<mat-icon
						class="setting-icon"
						fontIcon="mdi-cog"
						fontSet="mdi"></mat-icon>
				</a>
			}

			<a
				(click)="updateUser()"
				mat-icon-button
				matTooltip="{{ 'PROFILE' | translate }}"
				(keyup.enter)="handleClick()"
				tabindex="-1">
				<mat-icon
					class="user-setting-icon"
					fontIcon="mdi-account-cog"
					fontSet="mdi"></mat-icon>
			</a>
		</div>

		<span class="fx-grow-1"></span>

		<a
			[matTooltip]="(darkTheme ? 'LIGHT_MODE' : 'DARK_MODE') | translate"
			(keyup.enter)="handleClick()"
			(click)="toggleTheme()"
			mat-icon-button
			tabindex="-1">
			<mat-icon
				class="theme-icon"
				[fontIcon]="
					darkTheme ? 'mdi-white-balance-sunny' : 'mdi-weather-night'
				"
				fontSet="mdi"></mat-icon>
		</a>

		<button
			class="language-menu-button"
			[matMenuTriggerFor]="languageMenu"
			mat-icon-button>
			{{ uiLanguage | titlecase }}
		</button>

		<a
			(click)="logout()"
			class="logout"
			[ngStyle]="{ marginRight: profile && profile.admin ? '16px' : '0' }"
			(keyup.enter)="handleClick()"
			mat-icon-button
			matTooltip="{{ 'LOGOUT' | translate }}"
			tabindex="-1">
			<mat-icon
				class="logout-icon"
				fontIcon="mdi-power"
				fontSet="mdi"
				style="color: #f44336"></mat-icon>
		</a>

		<mat-menu #languageMenu="matMenu">
			<button mat-menu-item (click)="changeUILanguage('en')">En</button>
			<button mat-menu-item (click)="changeUILanguage('fr')">Fr</button>
			<button mat-menu-item (click)="changeUILanguage('nl')">Nl</button>
		</mat-menu>

		@if (profile?.admin) {
			<button
				[matMenuTriggerFor]="logoutMenu"
				mat-icon-button
				style="position: absolute; top: 3px; right: 0">
				<mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
			</button>
			<mat-menu #logoutMenu="matMenu">
				<button mat-menu-item (click)="logoutOthers()">
					{{ 'LOGOUT_OTHERS' | translate }}
				</button>
				<button mat-menu-item (click)="logoutMySessions()">
					{{ 'LOGOUT_MINE' | translate }}
				</button>
			</mat-menu>
		}
	</mat-toolbar>

	<div class="main-container">
		<router-outlet></router-outlet>
	</div>

	<audio
		#sound_player
		style="position: fixed; visibility: hidden"
		class="sound-player"
		src="assets/newmessage_sound.mp3"></audio>
</div>
