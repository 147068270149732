import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReportingRoutingModule } from './reporting-routing.module';
import { ReportingComponent } from './reporting.component';
import { ReportingService } from './reporting.service';
import { FireSharedModule, ReporterComponent } from '../shared';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReportingEditComponent } from './reporting-edit/reporting-edit.component';
import { ReportingHomeComponent } from './reporting-home/reporting-home.component';
import { AudioRecorderComponent } from './audio-recorder/audio-recorder.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { EmailSendComponent } from './email-send/email-send.component';
import { PathologyComponent } from './pathology/pathology.component';
// import {PlayerComponent} from './audio-recorder/player.component';
import { MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { ReportingFormComponent } from './reporting-form/reporting-form.component';
import { FtFormModule } from '@ft/form';
import { MatStepperModule } from '@angular/material/stepper';
import { PatientSecurityComponent } from './patient-security/patient-security.component';
import { PlayerComponent } from './audio-recorder/player.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReportingRoutingModule,
		MatToolbarModule,
		MatCardModule,
		MatTooltipModule,
		MatGridListModule,
		MatExpansionModule,
		FireSharedModule,
		MatRippleModule,
		MatBadgeModule,
		FtFormModule,
		MatStepperModule,
		PlayerComponent,
		ReporterComponent,
	],
	declarations: [
		ReportingComponent,
		ReportingEditComponent,
		ReportingHomeComponent,
		AudioRecorderComponent,
		AudioPlayerComponent,
		EmailSendComponent,
		// PlayerComponent,
		PathologyComponent,
		ReportingFormComponent,
		PatientSecurityComponent,
	],
	providers: [ReportingService],
})
export class ReportingModule {}
