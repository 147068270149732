import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhysiciansListComponent } from './physicians-list/physicians-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { FireSharedModule } from '../shared';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PhysicianService } from './physician.service';
import { PhysicianPermissionsComponent } from './physician-permissions/physician-permissions.component';
import { PhysicianExamsComponent } from './physician-exams/physician-exams.component';
import { PhysiciansRoutingModule } from './physicians-routing.module';

@NgModule({
	declarations: [
		PhysiciansListComponent,
		PhysicianPermissionsComponent,
		PhysicianExamsComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		MatCardModule,
		FireSharedModule,
		PhysiciansRoutingModule,
		MatGridListModule,
		MatToolbarModule,
		MatDatepickerModule,
		ReactiveFormsModule,
	],
	providers: [PhysicianService],
})
export class PhysiciansModule {}
