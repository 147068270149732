import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewChild, NgModule } from '@angular/core';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i4 from '@ngx-formly/core';
import { ɵobserve, FormlyModule } from '@ngx-formly/core';
import * as i6 from '@ngx-formly/core/select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FieldType, FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import * as i2 from '@angular/material/select';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import * as i1 from '@angular/material/core';
import { MatPseudoCheckboxModule } from '@angular/material/core';
function FormlyFieldSelect_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-option", 3);
    i0.ɵɵlistener("click", function FormlyFieldSelect_ng_template_0_Template_mat_option_click_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r5);
      const selectOptions_r3 = restoredCtx.selectOptions;
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.toggleSelectAll(selectOptions_r3));
    });
    i0.ɵɵelement(1, "mat-pseudo-checkbox", 4);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const selectOptions_r3 = ctx.selectOptions;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("state", ctx_r0.getSelectAllState(selectOptions_r3));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.props.selectAllOption, " ");
  }
}
const _c0 = a0 => ({
  selectOptions: a0
});
function FormlyFieldSelect_ng_container_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 7);
  }
  if (rf & 2) {
    const selectOptions_r6 = i0.ɵɵnextContext().ngIf;
    i0.ɵɵnextContext();
    const _r1 = i0.ɵɵreference(1);
    i0.ɵɵproperty("ngTemplateOutlet", _r1)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, selectOptions_r6));
  }
}
function FormlyFieldSelect_ng_container_3_ng_container_2_mat_optgroup_1_mat_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r14 = ctx.$implicit;
    i0.ɵɵproperty("value", child_r14.value)("disabled", child_r14.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", child_r14.label, " ");
  }
}
function FormlyFieldSelect_ng_container_3_ng_container_2_mat_optgroup_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-optgroup", 10);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_container_3_ng_container_2_mat_optgroup_1_mat_option_1_Template, 2, 3, "mat-option", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r10 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("label", item_r10.label);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", item_r10.group);
  }
}
function FormlyFieldSelect_ng_container_3_ng_container_2_mat_option_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r10 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("value", item_r10.value)("disabled", item_r10.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(item_r10.label);
  }
}
function FormlyFieldSelect_ng_container_3_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_container_3_ng_container_2_mat_optgroup_1_Template, 2, 2, "mat-optgroup", 8)(2, FormlyFieldSelect_ng_container_3_ng_container_2_mat_option_2_Template, 2, 3, "mat-option", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r10 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r10.group);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !item_r10.group);
  }
}
function FormlyFieldSelect_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, FormlyFieldSelect_ng_container_3_ng_container_1_Template, 1, 4, "ng-container", 5)(2, FormlyFieldSelect_ng_container_3_ng_container_2_Template, 3, 2, "ng-container", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const selectOptions_r6 = ctx.ngIf;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.props.multiple && ctx_r2.props.selectAllOption);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", selectOptions_r6);
  }
}
class FormlyFieldSelect extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        compareWith(o1, o2) {
          return o1 === o2;
        }
      }
    };
  }
  set select(select) {
    ɵobserve(select, ['_parentFormField', '_textField'], ({
      currentValue
    }) => {
      if (currentValue) {
        select._preferredOverlayOrigin = select._parentFormField.getConnectedOverlayOrigin();
      }
    });
  }
  getSelectAllState(options) {
    if (this.empty || this.value.length === 0) {
      return null;
    }
    return this.value.length !== this.getSelectAllValue(options).length ? 'indeterminate' : 'checked';
  }
  toggleSelectAll(options) {
    const selectAllValue = this.getSelectAllValue(options);
    this.formControl.setValue(!this.value || this.value.length !== selectAllValue.length ? selectAllValue : []);
    this.formControl.markAsDirty();
  }
  change($event) {
    this.props.change?.(this.field, $event);
  }
  _getAriaLabelledby() {
    if (this.props.attributes?.['aria-labelledby']) {
      return this.props.attributes['aria-labelledby'];
    }
    return this.formField?._labelId;
  }
  _getAriaLabel() {
    return this.props.attributes?.['aria-label'];
  }
  getSelectAllValue(options) {
    if (!this.selectAllValue || options !== this.selectAllValue.options) {
      const flatOptions = [];
      options.forEach(o => o.group ? flatOptions.push(...o.group) : flatOptions.push(o));
      this.selectAllValue = {
        options,
        value: flatOptions.filter(o => !o.disabled).map(o => o.value)
      };
    }
    return this.selectAllValue.value;
  }
}
FormlyFieldSelect.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldSelect_BaseFactory;
  return function FormlyFieldSelect_Factory(t) {
    return (ɵFormlyFieldSelect_BaseFactory || (ɵFormlyFieldSelect_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldSelect)))(t || FormlyFieldSelect);
  };
})();
FormlyFieldSelect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldSelect,
  selectors: [["formly-field-mat-select"]],
  viewQuery: function FormlyFieldSelect_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(MatSelect, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.select = _t.first);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 6,
  vars: 20,
  consts: [["selectAll", ""], [3, "id", "formControl", "formlyAttributes", "placeholder", "tabIndex", "required", "compareWith", "multiple", "errorStateMatcher", "aria-label", "aria-labelledby", "disableOptionCentering", "typeaheadDebounceInterval", "panelClass", "selectionChange"], [4, "ngIf"], [3, "click"], [1, "mat-option-pseudo-checkbox", 3, "state"], [3, "ngTemplateOutlet", "ngTemplateOutletContext", 4, "ngIf"], [4, "ngFor", "ngForOf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "label", 4, "ngIf"], [3, "value", "disabled", 4, "ngIf"], [3, "label"], [3, "value", "disabled", 4, "ngFor", "ngForOf"], [3, "value", "disabled"]],
  template: function FormlyFieldSelect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldSelect_ng_template_0_Template, 3, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementStart(2, "mat-select", 1);
      i0.ɵɵlistener("selectionChange", function FormlyFieldSelect_Template_mat_select_selectionChange_2_listener($event) {
        return ctx.change($event);
      });
      i0.ɵɵtemplate(3, FormlyFieldSelect_ng_container_3_Template, 3, 2, "ng-container", 2);
      i0.ɵɵpipe(4, "async");
      i0.ɵɵpipe(5, "formlySelectOptions");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("id", ctx.id)("formControl", ctx.formControl)("formlyAttributes", ctx.field)("placeholder", ctx.props.placeholder)("tabIndex", ctx.props.tabindex)("required", ctx.required)("compareWith", ctx.props.compareWith)("multiple", ctx.props.multiple)("errorStateMatcher", ctx.errorStateMatcher)("aria-label", ctx._getAriaLabel())("aria-labelledby", ctx._getAriaLabelledby())("disableOptionCentering", ctx.props.disableOptionCentering)("typeaheadDebounceInterval", ctx.props.typeaheadDebounceInterval)("panelClass", ctx.props.panelClass);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(4, 15, i0.ɵɵpipeBind2(5, 17, ctx.props.options, ctx.field)));
    }
  },
  dependencies: [i1.MatOption, i1.MatPseudoCheckbox, i2.MatSelect, i1.MatOptgroup, i3.NgControlStatus, i3.FormControlDirective, i4.ɵFormlyAttributes, i3.RequiredValidator, i5.NgIf, i5.NgTemplateOutlet, i5.NgForOf, i5.AsyncPipe, i6.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldSelect, [{
    type: Component,
    args: [{
      selector: 'formly-field-mat-select',
      template: `
    <ng-template #selectAll let-selectOptions="selectOptions">
      <mat-option (click)="toggleSelectAll(selectOptions)">
        <mat-pseudo-checkbox class="mat-option-pseudo-checkbox" [state]="getSelectAllState(selectOptions)">
        </mat-pseudo-checkbox>
        {{ props.selectAllOption }}
      </mat-option>
    </ng-template>

    <mat-select
      [id]="id"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [tabIndex]="props.tabindex"
      [required]="required"
      [compareWith]="props.compareWith"
      [multiple]="props.multiple"
      (selectionChange)="change($event)"
      [errorStateMatcher]="errorStateMatcher"
      [aria-label]="_getAriaLabel()"
      [aria-labelledby]="_getAriaLabelledby()"
      [disableOptionCentering]="props.disableOptionCentering"
      [typeaheadDebounceInterval]="props.typeaheadDebounceInterval"
      [panelClass]="props.panelClass"
    >
      <ng-container *ngIf="props.options | formlySelectOptions : field | async as selectOptions">
        <ng-container
          *ngIf="props.multiple && props.selectAllOption"
          [ngTemplateOutlet]="selectAll"
          [ngTemplateOutletContext]="{ selectOptions: selectOptions }"
        >
        </ng-container>
        <ng-container *ngFor="let item of selectOptions">
          <mat-optgroup *ngIf="item.group" [label]="item.label">
            <mat-option *ngFor="let child of item.group" [value]="child.value" [disabled]="child.disabled">
              {{ child.label }}
            </mat-option>
          </mat-optgroup>
          <mat-option *ngIf="!item.group" [value]="item.value" [disabled]="item.disabled">{{ item.label }}</mat-option>
        </ng-container>
      </ng-container>
    </mat-select>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    select: [{
      type: ViewChild,
      args: [MatSelect, {
        static: true
      }]
    }]
  });
})();
class FormlyMatSelectModule {}
FormlyMatSelectModule.ɵfac = function FormlyMatSelectModule_Factory(t) {
  return new (t || FormlyMatSelectModule)();
};
FormlyMatSelectModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMatSelectModule
});
FormlyMatSelectModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, MatSelectModule, MatPseudoCheckboxModule, FormlyMatFormFieldModule, FormlySelectModule, FormlyModule.forChild({
    types: [{
      name: 'select',
      component: FormlyFieldSelect,
      wrappers: ['form-field']
    }, {
      name: 'enum',
      extends: 'select'
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMatSelectModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldSelect],
      imports: [CommonModule, ReactiveFormsModule, MatSelectModule, MatPseudoCheckboxModule, FormlyMatFormFieldModule, FormlySelectModule, FormlyModule.forChild({
        types: [{
          name: 'select',
          component: FormlyFieldSelect,
          wrappers: ['form-field']
        }, {
          name: 'enum',
          extends: 'select'
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldSelect, FormlyMatSelectModule };
