import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth';
import { fromEvent, merge, Observable, Subject, timer } from 'rxjs';
import {
	debounce,
	debounceTime,
	filter,
	map,
	switchMap,
	tap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class AutoLogoutService {
	private authService = inject(AuthService);
	private _http = inject(HttpClient);
	private readonly timeOfInactivity: number = 10; // Change this to your desired time in seconds

	private readonly idleEvents = [
		'click',
		'keypress',
		'mousemove',
		'touchstart',
		'keydown',
		'scroll',
	];

	private userActive$: Observable<any> = merge(
		...this.idleEvents.map(event => fromEvent(document, event))
	);

	private _fetchInactivityTimeout(): Observable<number> {
		return this._http.get<number>('/api/system/inactivity-timeout');
	}

	public startMonitoring(): void {
		// Fetch from backend the configured time (in minutes) before session logout
		this._fetchInactivityTimeout().subscribe((_timeout: number) => {
			console.log(
				`%cSession expires in ${_timeout} minutes !`,
				'background-color: orange;font-weight:bold;color: black;padding: 2px 4px; border-radius:3px;'
			);
			this.userActive$
				.pipe(
					debounceTime(100),
					map(() => timer(_timeout * 60 * 1000)),
					switchMap(timer$ => timer$.pipe(filter(() => true)))
				)
				.subscribe(() => this.logout());
		});
	}

	// Call logout function when timer finishes
	logout(): void {
		console.log('logout');
		this.authService.logout().subscribe();
	}
}
