import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerService } from './scheduler.service';
import { SchedulerComponent } from './scheduler.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
	FireSharedModule,
	FtCalendarComponent,
	SharedService,
} from '../shared';
import { SchedulerRoutingModule } from './scheduler-routing.module';
import { ProcedureCodeSearchComponent } from './procedure-code-search/procedure-code-search.component';
import { ExternalAppointmentsComponent } from './external-appointments';
import { EventEditComponent } from './event-edit/event-edit.component';
import { AvailabilityCheckComponent } from './availability-check/availability-check.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { ScheduleManagerComponent } from './schedule-manager/schedule-manager.component';
import { AppointmentEditComponent } from './appointment-edit/appointment-edit.component';
import { AppointmentsForResultComponent } from './appointments-for-result';
import { ExamSchedulerComponent } from './exam-scheduler/exam-scheduler.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ProcedureCodesComponent } from './procedure-codes/procedure-codes.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MatCardModule,
		FireSharedModule,
		MatGridListModule,
		MatToolbarModule,
		MatDatepickerModule,
		MatOptionModule,
		MatSelectModule,
		MatRadioModule,
		ReactiveFormsModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatTabsModule,
		MatListModule,
		MatChipsModule,
		SchedulerRoutingModule,
		MatExpansionModule,
		MatStepperModule,
		MatBadgeModule,
	],
	declarations: [
		SchedulerComponent,
		ProcedureCodeSearchComponent,
		ExternalAppointmentsComponent,
		EventEditComponent,
		AvailabilityCheckComponent,
		ScheduleManagerComponent,
		FtCalendarComponent,
		AppointmentEditComponent,
		AppointmentsForResultComponent,
		ExamSchedulerComponent,
		ProcedureCodesComponent,
	],
	providers: [SchedulerService, SharedService],
})
export class SchedulerModule {}
