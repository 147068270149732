import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagingRoutingModule } from './messaging-routing.module';
import { MessagingComponent } from './messaging.component';
import { MessagingService } from './messaging.service';
import { UserSelectComponent } from './user-select';
import { EmojisDisplayPipe } from './pipes';
import { MatBadgeModule } from '@angular/material/badge';
import { DeleteOptionComponent } from './delete-option.component';
import { MessagePopupComponent } from './message-popup/message-popup.component';
import { AudioMessageComponent } from './audio-message/audio-message.component';
import { MatSliderModule } from '@angular/material/slider';
import { MessageRecorderComponent } from './message-recorder/message-recorder.component';
import { FireSharedModule } from '../shared';

@NgModule({
	declarations: [
		MessagingComponent,
		UserSelectComponent,
		EmojisDisplayPipe,
		DeleteOptionComponent,
		MessagePopupComponent,
		AudioMessageComponent,
		MessageRecorderComponent,
	],
	imports: [
		CommonModule,
		MessagingRoutingModule,
		MatBadgeModule,
		MatSliderModule,
		FireSharedModule,
	],
	providers: [MessagingService],
})
export class MessagingModule {}
