import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginGuard } from './auth';
import { MainComponent } from './main.component';
import { DocReaderComponent } from './shared/doc-reader/doc-reader.component';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: '/scheduler/schedule-manager' },
	{
		path: 'ft-queue',
		loadComponent: () =>
			import('./shared/queue/queue.component').then(
				c => c.QueueComponent
			),
	},
	{
		path: 'login',
		canActivate: [LoginGuard],
		loadComponent: () =>
			import('./auth/login.component').then(c => c.LoginComponent),
	},
	{
		path: '',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: 'physicians',
				loadChildren: () =>
					import('./physicians/physicians-routing.module').then(
						m => m.PhysiciansRoutingModule
					),
			},
			{
				path: 'scheduler',
				loadChildren: () =>
					import('./scheduler/scheduler-routing.module').then(
						m => m.SchedulerRoutingModule
					),
			},
			{
				path: 'workflow',
				loadChildren: () =>
					import('./workflow/workflow-routing.module').then(
						m => m.WorkflowRoutingModule
					),
			},
			{
				path: 'patients',
				loadChildren: () =>
					import('./patient/patient-routing.module').then(
						m => m.PatientRoutingModule
					),
			},
			{
				path: 'reporting',
				loadChildren: () =>
					import('./reporting/reporting-routing.module').then(
						m => m.ReportingRoutingModule
					),
			},
			{
				path: 'setting',
				loadChildren: () =>
					import('./setting/setting-routing.module').then(
						m => m.SettingRoutingModule
					),
			},
			{
				path: 'statistic',
				loadChildren: () =>
					import('./statistic/statistic-routing.module').then(
						m => m.StatisticRoutingModule
					),
			},
			{
				path: 'messaging',
				loadChildren: () =>
					import('./messaging/messaging-routing.module').then(
						m => m.MessagingRoutingModule
					),
			},
			{
				path: 'logs',
				loadComponent: () =>
					import('./shared/logs/logs.component').then(
						c => c.LogsComponent
					),
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'external-viewer/:study',
		canActivate: [AuthGuard],
		loadComponent: () =>
			import('./external-viewer/external-viewer.component').then(
				c => c.ExternalViewerComponent
			),
	},
	{
		path: 'doc-reader/:docId',
		canActivate: [AuthGuard],
		component: DocReaderComponent,
	},
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: '/scheduler/schedule-manager',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
